<template>
  <b-tab title="Cutoff">
    <b-row class="mt-1 border rounded p-1">
      <b-col md="6"> <!-- Cutoffs -->
        <div>
          <h4>Cutoffs actualmente en la membresía</h4>

          <b-table
            v-if="currentCutoff.length"
            :items="currentCutoff"
            :fields="currentCutoffFields"
            responsive
            sticky-header="500px"
            sticky-top
          >

            <template #cell(days)="data">
              <ValidationProvider :name="'días-cutoff-'+data.index+1" rules="required" >
                <b-form-group class="custom-input-margin" slot-scope="{ valid, errors }">
                  <b-form-input
                    step="0.01"
                    min="0.00"
                    max="100.00"
                    class="form-control"
                    type="number"
                    :disabled="temporalDataStatus"
                    v-model="data.item.days"
                    :state="errors[0] ? false : valid ? true : null"/>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </template>

            <template #cell(delete)="data">
              <b-button
                size="sm"
                @click="deleteCuttoff(data.item)"
                variant="danger"
                v-if="!temporalDataStatus"
              > <feather-icon icon="Trash2Icon" size="12" />
              </b-button>
            </template>
          </b-table>

          <div v-else>
            <b-alert show variant="danger" >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="InfoIcon"
                />
                <span class="ml-25">Esta membresía no tiene cuttoffs agregados.</span>
              </div>
            </b-alert>
          </div>
        </div>
      </b-col>

      <b-col md="6"> <!-- Cutoffs -->
        <div>
          <h4>Agregar nuevos cutoffs a la membresía</h4>
          <b-table
            v-if="cutoffsAvailable.length"
            :items="cutoffsAvailable"
            :fields="newCutoffFields"
            responsive
            sticky-header="500px"
            sticky-top
          >

            <template #cell(days)="data">
              <ValidationProvider :name="'días-nuevo-cutoff-'+data.index+1" rules="" >
                <b-form-group class="custom-input-margin" slot-scope="{ valid, errors }">
                  <b-form-input
                    step="0.01"
                    min="0.00"
                    max="100.00"
                    class="form-control"
                    type="number"
                    :disabled="temporalDataStatus"
                    v-model="data.item.days"
                    :state="errors[0] || !valid ? false : null"
                    @keypress="onlyNumber"
                    @input="updateDataCutoff(data.item)"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </template>

            <template #cell(add)="data">
              <b-form-checkbox
                v-model="data.item.isChecked"
                :disabled="temporalDataStatus"
                switch
                inline
                :unchecked-value="false"
                class="custom-control-success"
                @change="newCutoff(data.item)"
              >
              </b-form-checkbox>
            </template>
          </b-table>

          <div v-else>
            <b-alert show variant="danger" >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="InfoIcon"
                />
                <span class="ml-25">Opciones de cutoff ya agregadas.</span>
              </div>
            </b-alert>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapActions, mapMutations} from 'vuex'
import { utils } from '@/modules/fivesClub/mixins/utils'

export default {
  mixins: [utils],
  created(){
    this.setTableActionButton()
  },
  props: {
    currentCutoff: {
      type: Array,
      required: true,
    },
    cutoffsAvailable: {
      type: Array,
      required: true,
    },
    isUpdatingCutoffs: {
      type: Boolean,
      required: true,
    },
    temporalDataStatus: {
      type: Boolean,
      required: true,
    },
    idMembership: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isUpdating: false,
      filterCurrentBenefits:'',
      filterAvailableBenefits: '',
      currentCutoffFields: [
        { key:'name', label: 'NOMBRE', class: 'font-weight-bold' },
        { key:'days', label: 'DÍAS' },
        { key:'delete', label: 'BORRAR', class: 'text-center' },
      ],
      newCutoffFields: [
        { key:'label', label: 'Temporada', class: 'font-weight-bold' },
        { key:'days', label: 'Días' },
        { key:'add', label: 'Agregar', class: 'text-center' },
      ],
    }
  },
  methods: {
    ...mapMutations('fivesClubCatalogs', ['addRemoveNewCutoffMembership', 'updateNewCutoffMembership']),
    ...mapActions('fivesClubCatalogs', ['deleteInMembership']),
    async deleteCuttoff(cuttoff){
      const payloadAction= { title: '¿Está seguro?', text: '¿Seguro de borrar este cutoff?', showDenyButton: true, confirmButtonText: 'Sí, borrar', denyButtonText: 'Cancelar'}
      const {isConfirmed} = await this.$swal.fire(payloadAction)

      if(isConfirmed){
        this.$emit('set-loader', {loader: 'isUpdatingCutoffs', value: true})
        this.loadingProcess = true
        const { id } = cuttoff
        const payload = { idMembership: this.idMembership, idCutoff: id }
        const { status, message } =  await this.deleteInMembership(payload) // message viene igual en la respuesta
        if( status ) this.$emit('reload-data', message)
        this.$emit('set-loader', {loader: 'isUpdatingCutoffs', value: false})
      }
    },
    newCutoff(cutoff){
      const { isChecked, days, id, type, season } = cutoff
      const payload = { isChecked, days: days ? parseFloat(days) : 0, idCutoff:id, status: true, type, season }
      this.addRemoveNewCutoffMembership(payload)
    },
    updateDataCutoff(cutoff){
      const { isChecked, days, id, type, season } = cutoff
      if(isChecked){
        const payload = { isChecked, days: days ? parseFloat(days) : 0, idCutoff:id, status: true, type, season  }
        this.updateNewCutoffMembership(payload)
      }
    },
    setTableActionButton(){
      if (this.temporalDataStatus) {
        this.currentCutoffFields = this.currentCutoffFields.filter(fields => fields.key != 'delete')
        this.newCutoffFields = this.newCutoffFields.filter(fields => fields.key != 'add')
      }
    }
  }
};
</script>

<style lang="scss" scoped>

</style>