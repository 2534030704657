<template>
  <b-card v-if="selectedMembership" class="p-1">
    <div class="d-flex justify-content-between">
      <span>
        <h3>Membresía: {{originalDataMembership.code}}</h3>
      </span>
      <b-button size="sm" variant="warning" @click="goToHome"> <b-icon-arrow-left></b-icon-arrow-left> Regresar</b-button>
    </div>

    <b-overlay :show="isUpdatingMembership" rounded>
      <ValidationObserver v-slot="{ invalid }" tag="form" ref="membershipEditForm" v-if="selectedMembership" >
        <b-alert variant="warning" show v-if="isWaitingForAuth" class="mt-1">
          <div class="alert-body text-center">
            <span> Pendiente por autorizar </span>
          </div>
        </b-alert>
        <b-form @submit.prevent="update">
          <b-tabs v-model="tabIndex">
            <!-- Datos principales -->
            <b-tab title="General">
              <b-row class="mt-1 border rounded p-1">
                <b-col md="">
                  <ValidationProvider rules="required" name="codigo">
                    <b-form-group slot-scope="{ valid, errors }">
                      <label>Código</label>
                      <b-form-input
                        class="form-control"
                        type="text"
                        v-model="selectedMembership.code"
                        :disabled="isWaitingForAuth"
                        :state="errors[0] ? false : valid ? true : null"
                      />
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="">
                  <ValidationProvider rules="required" name="nombre">
                    <b-form-group slot-scope="{ valid, errors }">
                      <label>Nombre</label>
                      <b-form-input
                        class="form-control"
                        type="text"
                        v-model="selectedMembership.name"
                        :disabled="isWaitingForAuth"
                        :state="errors[0] ? false : valid ? true : null"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="">
                  <ValidationProvider rules="required" name="Tipo de pago">
                    <b-form-group slot-scope="{ valid, errors }">
                      <label>Tipo de pago</label>
                      <b-form-select
                        :state="errors[0] ? false : valid ? true : null"
                        v-model="selectedMembership.paymentType"
                        :disabled="isWaitingForAuth"
                      >
                        <option value="">Tipo de pago</option>
                        <option v-for="pt in paymentTypes" :key="pt.id" :value="pt.id">
                          {{ pt.name }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="">
                  <ValidationProvider rules="required" name="Tipo de retorno">
                    <b-form-group slot-scope="{ valid, errors }">
                      <label>Tipo de retorno</label>
                      <b-form-select
                        :state="errors[0] ? false : valid ? true : null"
                        v-model="selectedMembership.returnType"
                        :disabled="isWaitingForAuth"
                      >
                        <option value="">Tipo de retorno</option>
                        <option v-for="ret in retornos" :key="ret.id" :value="ret.id">
                          {{ ret.name }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="12" class="mb-2" />

                <b-col md="1" class="mr-1">
                  <div class="mb-1">
                    <b-form-checkbox
                      v-model="selectedMembership.status"
                      :disabled="isWaitingForAuth"
                      :value="true"
                      :unchecked-value="false"
                      switch
                      class="custom-control-success"
                    > Activo
                    </b-form-checkbox>
                  </div>
                </b-col>

                <b-col md="2" class="mr-1">
                  <div class="mb-1">
                    <b-form-checkbox
                      v-model="selectedMembership.canSubscribe"
                      :disabled="isWaitingForAuth"
                      :value="true"
                      :unchecked-value="false"
                      switch
                      class="custom-control-success"
                    > Puede acceder a suscripciónes
                    </b-form-checkbox>
                  </div>
                </b-col>

                <b-col md="12" class="mb-2" />

                <b-col md="6">
                  <ValidationProvider name="blackouts">
                    <b-form-group label="Blackouts" slot-scope="{ valid, errors }">
                      <v-select
                        v-model="selectedMembership.membershipBlackouts"
                        :disabled="isWaitingForAuth"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        @option:deselected="deleteBlackout"
                        multiple
                        label="name"
                        :options="blackouts"
                        :state="errors[0] ? false : valid ? true : null"
                        class="blackouts-select"
                      />
                      <div class="text-danger" style="font-size: 0.857rem">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="6">
                  <ValidationProvider name="Notas">
                    <b-form-group label="Notas de membresías">
                      <b-form-textarea
                        v-model="selectedMembership.notes"
                        :disabled="isWaitingForAuth"
                        placeholder="Escribe la nota"
                        rows="4"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-tab>

            <MembershipBenefits
              ref="MembershipBenefitsForm"
              :currentBenefits="selectedMembership.membershipBenefits"
              :benefitsAvailable="selectedMembership.benefitsAvailable"
              :isUpdatingBenefits="isUpdatingBenefits"
              :temporalDataStatus="isWaitingForAuth"
              :idMembership="selectedMembership.id"
              @reload-data="reloadDataAftterUpdate"
              @set-loader="setLoader"
            />

            <MembershipCutoff
              ref="MembershipCutoffForm"
              :currentCutoff="selectedMembership.membershipCutoff"
              :cutoffsAvailable="cutoffsAvailable"
              :isUpdatingCutoffs="isUpdatingCutoffs"
              :temporalDataStatus="isWaitingForAuth"
              :idMembership="selectedMembership.id"
              @reload-data="reloadDataAftterUpdate"
              @set-loader="setLoader"
            />

            <MembershipCancelationPolicies
              ref="MembershipCancelationPoliciesForm"
              :currentPolicies="selectedMembership.cancelationPolicies"
              :newPolicies="selectedMembership.newPolicies"
              :isUpdatingPolicies="isUpdatingPolicies"

              :temporalDataStatus="isWaitingForAuth"
              :idMembership="selectedMembership.id"
              @reload-data="reloadDataAftterUpdate"
              @set-loader="setLoader"
            />

          </b-tabs>

          <!-- Guardar -->
          <b-row class="mt-2">
            <b-col>
              <div class="float-right">
                <b-button
                  v-if="!isWaitingForAuth"
                  type="submit"
                  class="btn-block"
                  variant="primary"
                  :disabled="invalid || isUpdatingSomething"
                  v-can="'fivesclub_membership_show_membership_list_action_button_edit_membership_save_button'"
                >
                  <b-spinner small v-if="isUpdatingSomething" /> Guardar
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-card>
  <!-- No membership -->
  <b-card v-else >
    <b-col md="12">
      <div class="d-flex justify-content-between">
        <div><h3>Editar membresía</h3></div>
        <div>
          <b-button size="sm" variant="warning" @click="goToHome"> <b-icon-arrow-left></b-icon-arrow-left> Regresar</b-button>
        </div>
      </div>
    </b-col>
    <b-card-body v-if="isloadingMembership" class="text-center">
      <b-spinner/> <br> Cargando información de membresía
    </b-card-body>
    <b-card-body v-else>
      <b-alert variant="danger" show >
        <div class="alert-body"> No se ha encontrado una membresía con este registro </div>
      </b-alert>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { showAlertMessage, toJson } from '@/helpers/helpers'
import vSelect from 'vue-select'
import { createArrayToUpdateMembership, benefitsCuttoffBlackouts, verifyDistincMembershipData } from '@/helpers/fivesClubHelper'
import MembershipBenefits from '@/modules/fivesClub/components/catalogs/memberships/membershipEdition/MembershipBenefits'
import MembershipCutoff from '@/modules/fivesClub/components/catalogs/memberships/membershipEdition/MembershipCutoff'
import MembershipCancelationPolicies from '@/modules/fivesClub/components/catalogs/memberships/membershipEdition/MembershipCancelationPolicies'

import { optionsSelectCutoff } from '@/data/fivesClub'
import { utils } from '@/modules/fivesClub/mixins/utils'

export default {
  mixins: [utils],
  components: {
    vSelect,
    MembershipBenefits,
    MembershipCutoff,
    MembershipCancelationPolicies
  },
  async created(){
    await this.init()
    this.setBenefitsAvailable()
  },
  data() {
    return {
      tabIndex: 0,
      originalDataMembership: null,
      selectedMembership: null,
      membership: this.$route.params.idMembership,
      isUpdatingMembership: false,
      isloadingMembership: false,
      optionsSelectCutoff,
      cutoff: optionsSelectCutoff,
      filter: "",
      filterCutoff: "",
      isUpdatingBenefits: false,
      isUpdatingCutoffs: false,
      isUpdatingPolicies: false
    }
  },
  computed: {
    ...mapState('fivesClubCatalogs', ['paymentTypes', 'retornos', 'typeMemberships', 'blackouts', 'benefitOptions', ]),
    ...mapState('auth', ['user']),
    cutoffsAvailable(){
      let cutoffs = []
      const cutoffMembership =this.selectedMembership.membershipCutoff
      optionsSelectCutoff.forEach( cutoff => {
        const { type, season } = cutoff
        cutoff.isAvailable = true
        cutoff.isChecked = false

        cutoff.days = ''
        const cutoffData = cutoffMembership.find( c => c.type == type && c.season == season )
        if(cutoffData){
          cutoff.isAvailable = false
          cutoff.days = cutoffData.days
        }
        cutoffs.push(cutoff)
      })
      return cutoffs.filter( c => c.isAvailable)
    },
    isUpdatingSomething(){
      return (
        !!this.isUpdatingPolicies ||
        !!this.isUpdatingMembership ||
        !!this.isUpdatingBenefits ||
        !!this.isUpdatingCutoffs
      )
    },
    isWaitingForAuth(){
      const authBenefits = this.originalDataMembership.membershipBenefits.filter(benefit => benefit.temporalDataStatus)
      const authCutoffs = this.originalDataMembership.membershipCutoff.filter(cutoff => cutoff.temporalDataStatus)
      const authPolicies = this.originalDataMembership.cancelationPolicies.filter(policie => policie.temporalDataStatus)
      const auth = !!this.originalDataMembership.temporalDataStatus || authBenefits.length > 0 || authCutoffs.length > 0 || authPolicies.length > 0
      return Boolean(auth)
    }
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['fetchMembershipsInfo', 'updateMembership', 'fetchMemberships', 'deleteInMembership', 'getInitialContentForMembershipsEdition']),
    ...mapMutations('fivesClubCatalogs', [ 'setSelectedMembership', 'setMemberships', 'setAvailableBenefits']),
    async init(){
			if(this.isANumber(this.membership)){
        this.isloadingMembership = true

        await this.getInitialContentForMembershipsEdition({
          paymentTypes: !this.paymentTypes?.length > 0,
          retornos: !this.retornos?.length > 0,
          blackouts: !this.blackouts?.length > 0,
          benefits: true,
          // benefits: !this.benefits?.length > 0,
        })

        const membershipData = await this.fetchMembershipsInfo({membership: this.membership, typeReq: 'data'})
        if (membershipData?.length > 0) {
          const data = structuredClone(membershipData[0])
          const membresia = benefitsCuttoffBlackouts(data)
          membresia.newBenefits = []
          membresia.newCutoffs = []
          membresia.benefitsAvailable = []
          membresia.newPolicies = []
          this.setSelectedMembership(membresia)
          this.selectedMembership = membresia
          this.originalDataMembership = structuredClone(membresia)
        }
        this.isloadingMembership = false
      }
    },
    isANumber(string){
      return string ? !isNaN( string ) && string > 0 : false
    },
    setBenefitsAvailable(){
      if(this.selectedMembership){
        const idBenefits = this.selectedMembership.membershipBenefits.map( benefit => benefit.idBenfit) //array de idx de benefits que tiene la membresia
        const benefitsDisponibles = this.benefitOptions.filter(({id}) => !idBenefits.includes(id)) //filtro benefits que sean diferentes al que trae la membresia
        benefitsDisponibles.forEach(benefit => {
          benefit.qty =  ''
          benefit.discount =  ''
          benefit.isChecked =  false
        })
        this.setAvailableBenefits(benefitsDisponibles)
      }
    },
    async update() {

      const payloadAction= { title: '¿Está seguro?', text: '¿Seguro de realizar la actualización?', confirmButtonText: 'Sí, guardar', denyButtonText: 'Cancelar'}
      const isConfirmed = await this.confirmAction(payloadAction)
      const validPriority = this.$refs.MembershipCancelationPoliciesForm.checkPriority()
      if (!validPriority) {
        this.tabIndex = 3
        return false
      }

      this.isUpdatingMembership = true
      this.isUpdatingBenefits = true
      this.isUpdatingCutoffs = true
      this.isUpdatingPolicies = true
      const { membershipBlackouts, membershipBenefits, membershipCutoff, cancelationPolicies, newBenefits, newCutoffs, newPolicies, } = this.selectedMembership //destructuring
      const { membershipBlackouts: originalBlackouts, membershipBenefits: originalBenefits, membershipCutoff: originalCutoff, cancelationPolicies: originalnPolicies } = this.originalDataMembership //destructuring
      const [ blackouts, benefits, cuttoffs, policies ] = createArrayToUpdateMembership({ membershipBlackouts, membershipBenefits, membershipCutoff, newBenefits, newCutoffs, cancelationPolicies, newPolicies })

      const modifiedArrays = verifyDistincMembershipData({
        values: ['blackouts', 'benefits', 'cuttoffs', 'policies'],
        oldData: {blackouts: originalBlackouts.map(bl => bl.id), benefits: originalBenefits, cuttoffs: originalCutoff, policies: originalnPolicies},
        newData: {blackouts, benefits, cuttoffs, policies}
      })

      const payload = {
        idUser: this.user.idUser,
        idMembership: this.selectedMembership.id,
        idType: null,
        name: this.selectedMembership.name,
        code: this.selectedMembership.code,
        paymentType: this.selectedMembership.paymentType,
        returnType: this.selectedMembership.returnType,
        excludeCalculo: Boolean(this.selectedMembership.excludeFeeCalculation),
        showReport: Boolean(this.selectedMembership.showReport),
        status: Boolean(this.selectedMembership.status),
        canSubscribe: Boolean(this.selectedMembership.canSubscribe),
        note:this.selectedMembership.notes,
        //arreglos
        benefits: modifiedArrays.benefits,
        blackout: modifiedArrays.blackouts,
        cutoff: modifiedArrays.cuttoffs,
        policies: modifiedArrays.policies,
      }

      if (isConfirmed) {
        const { status, message } = await this.updateMembership(payload);
        if (status) {
          this.selectedMembership.membershipBenefits.forEach(element => {
            element.qty = ''
            element.amount = ''
            element.isChecked = false
          })
          showAlertMessage('Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
          await this.init()
          this.setBenefitsAvailable()
          this.$refs.membershipEditForm.reset()

          const refs = ['MembershipBenefitsForm', 'MembershipCutoffForm', 'MembershipCancelationPoliciesForm']

          refs.forEach(ref => {
            this.$refs[ref].setTableActionButton()
          })

          //vuelvo a pedir la info del back
          const memberships = await this.fetchMemberships()
          this.setMemberships(memberships)
          // location.reload() //recargo forzado
          // this.$emit('set-tab-index', 0) //go to tab 1, to show list
        }
      }
      this.isUpdatingPolicies = false
      this.isUpdatingMembership = false
      this.isUpdatingBenefits = false
      this.isUpdatingCutoffs = false

    },
    setMembershipToEdit(membership) {
      const data = {...membership } //rompo la fucking referencia
      const membresia = benefitsCuttoffBlackouts(data)
      membresia.newBenefits = []
      membresia.newCutoffs = []
      return membresia
    },
    setLoader(data){
      const {loader, value} = data
      this[loader] = value
    },
    async reloadDataAftterUpdate(message){
      showAlertMessage('Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
      await this.init()
      this.setBenefitsAvailable()
      this.$refs.membershipEditForm.reset()

      const memberships = await this.fetchMemberships()
      this.setMemberships(memberships)
    },
    async confirmAction(payloadAction) {
      const { title, text , confirmButtonText, denyButtonText } = payloadAction
      const { isConfirmed } = await this.$swal.fire({ title, text, showDenyButton: true, confirmButtonText, denyButtonText})
      return isConfirmed;
    },
    async deleteBlackout(option) {
      if(option){
        const hasBlackout = this.originalDataMembership.membershipBlackouts.some(blackout => blackout.id == option.id)
        if (hasBlackout) {
          const payload = { idMembership: this.selectedMembership.id, idBlackout: option.id }
          const { status } =  await this.deleteInMembership(payload) //message viene igual en la respuesta
          if(status){
            this.setMemberships([])
            const memberships = await this.fetchMemberships()
            this.setMemberships(memberships)
          }
        }
      }
    },
    goToHome(){
      this.setSelectedMembership(null)
      this.$router.push({ name: 'memberships' })
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.border-righ.col-md-6 {
    border-left: 1px solid #dee2e6 ;
}
.mb-0.table-responsive {
    max-height: 369px;
}

.tbodyDiv {
  max-height: 30rem;
  overflow: auto;
}

.border-benfits-cutoff{
  border: 2px solid #333366;
}

.border-add-benfits-cutoff:hover{
  border: 2px solid #28c76f !important;
  transition: all 0.5s ease;
}

.border-add-benfits-cutoff{
  border: 2px solid #dff4f5 !important;
  transition: all 0.5s ease;
}

.custom-input-margin{
  margin: 0.34rem 0;
}

.vs--disabled .vs__selected-options .vs__selected{
  background-color: #39406665;
}
</style>
