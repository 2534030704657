<template>
  <b-tab title="Políticas de Cancelación">
    <b-row class="mt-1 border rounded p-1">

      <b-col md="12" class="mb-1" v-if="!temporalDataStatus"> <!-- Políticas de cancelación -->
        <div>
          <h4>Agregar políticas de cancelación a la membresía</h4>

          <ValidationObserver
            v-slot="{ invalid }"
            tag="form"
            :disabled="true"
            ref="membershipAddPoliciesForm"
          >
            <b-form @submit.prevent="addPolicy" class="mb-1">
              <b-row align-v="end">
                <b-col md="">
                  <ValidationProvider name="Nombre" rules="required|max:100">
                    <b-form-group slot-scope="{ errors }" class="m-0">
                      <label class="mt-2">Nombre*</label>
                      <b-form-input
                        @keypress="onlyNumbersLetters"
                        placeholder="Nombre política"
                        v-model="newPolicy.name"
                        :state="errors.length > 0 ? false : null"
                      />
                      <b-form-invalid-feedback style="position: absolute;">{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="">
                  <ValidationProvider name="Fecha Inicio" rules="">
                    <b-form-group slot-scope="{ errors }" class="m-0">
                      <label class="mt-2">Fecha Inicio</label>
                      <flat-pickr
                        :config="setDateInConfigGlobal"
                        :class="`form-control flatpickr-color ${errors.length > 0 ? 'is-invalid' : ''}`"

                        v-model="newPolicy.dateInit"
                        placeholder="Seleccione una fecha"
                        :state="errors.length > 0 ? false : null"
                      />
                        <!-- :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" -->
                      <!-- <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback> -->
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="">
                  <ValidationProvider name="Fecha Fin" rules="">
                    <b-form-group slot-scope="{ errors }" class="m-0">
                      <label class="mt-2">Fecha Fin</label>
                      <flat-pickr
                        :config="setDateOutConfigGlobal"
                        :class="`form-control flatpickr-color ${errors.length > 0 ? 'is-invalid' : ''}`"

                        v-model="newPolicy.dateEnd"
                        placeholder="Seleccione una fecha"
                        :state="errors.length > 0 ? false : null"
                      />
                        <!-- :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" -->
                      <!-- <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback> -->
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col>
                  <ValidationProvider name="Días antes de penalizar" rules="required|max_value:366">
                    <b-form-group slot-scope="{ errors }" class="m-0">
                      <label class="mt-2">Días antes de penalizar* </label>
                      <b-form-input
                        step="1"
                        min="0"
                        max="366"
                        maxlength="3"
                        class="form-control"
                        type="number"
                        placeholder="Indique días"
                        @keypress="onlyNumber"
                        :disabled="temporalDataStatus"
                        v-model="newPolicy.days"
                        :state="errors.length > 0 ? false : null"
                      />
                      <b-form-invalid-feedback style="position: absolute;">{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="1">
                  <b-button
                    type="submit"
                    class="float-right mt-2"
                    variant="primary"
                    :disabled="invalid"
                  > <feather-icon icon="PlusIcon" size="12" />
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>

        </div>
      </b-col>

      <b-col md="12"> <!-- Políticas de Cancelación -->
        <div class="mt-2">
          <h4>Políticas de cancelación en la membresía</h4>

          <div v-if="policies.length">
            <b-overlay :show="isUpdatingPolicies">
              <table class="table table-responsive custom-table-overflow">
                <thead class="sticky-top">
                  <tr>
                    <th class="custom-table-title-padding col-md-1 text-nowrap">
                      <label> Prioridad </label>
                    </th>
                    <th class="custom-table-title-padding col-md-2">
                      <label> Nombre </label>
                    </th>
                    <th class="custom-table-title-padding col-md-2">
                      <label> Fecha inicio </label>
                    </th>
                    <th class="custom-table-title-padding col-md-2">
                      <label> Fecha fin </label>
                    </th>
                    <th class="custom-table-title-padding col-md-1">
                      <label> Días antes de penalizar </label>
                    </th>

                    <th class="custom-table-title-padding col-md-1 text-center text-nowrap">
                      <label> Status </label>
                    </th>
                    <th class="custom-table-title-padding col-md-1 text-center text-nowrap">
                      <label> Borrar </label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(policy, index) in policies"
                    :key="policy.id ? policy.id : policy.key"
                    :class="`custom-table-padding-tr ${rowClass(policy)}`"
                  >
                    <td>
                      <ValidationProvider
                        :name="'Prioridad política '+(index+1)"
                        :rules="`required|min_value:1|max_value:${policies.length}`"
                        v-slot="v"
                        :id="'Prioridad política '+(index+1)"
                      >
                        <b-form-group class="wth-4 m-0">
                          <b-form-input
                            size="sm"
                            step="1"
                            min="0"
                            class="form-control"
                            type="number"
                            :disabled="temporalDataStatus || !policy.status"
                            v-model="policy.priority"
                            @keypress="onlyNumber"
                            :state="v.errors[0] ? false : null"
                          />
                        </b-form-group>

                        <b-popover v-if="!!v.errors[0]" :target="'Prioridad política '+(index+1)" triggers="hover" placement="auto">
                          <!-- <template #title>{{ policy.name }}</template> -->
                          <label class="text-danger">{{ v.errors[0] }}</label>
                        </b-popover>
                      </ValidationProvider>
                    </td>
                    <td scope="row" class="">
                      <div class="ml-1">{{ policy.name }}</div>
                    </td>
                    <td scope="row">
                      <ValidationProvider :name="'Fecha Inicio politica '+ (index+1)" rules="">
                        <b-form-group class="custom-input-margin" slot-scope="{ errors }">
                          <flat-pickr
                            :config="dateInConfig"
                            :class="`form-control flatpickr-color ${errors.length > 0 ? 'is-invalid' : ''}`"
                            v-model="policy.dateInit"
                            :disabled="!policy.status || temporalDataStatus"
                            placeholder="Seleccione una fecha"
                            :state="errors.length > 0 ? false : null"
                          />
                            <!-- @input="verifyDatePolicy('dateInit', policy, policy.dateInit)" -->
                            <!-- :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" -->
                          <!-- <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback> -->
                        </b-form-group>
                      </ValidationProvider>
                    </td>
                    <td scope="row">
                      <ValidationProvider :name="'Fecha Fin politica '+ (index+1)" rules="">
                        <b-form-group class="custom-input-margin" slot-scope="{ errors }">
                          <flat-pickr
                            :config="dateInConfig"
                            :class="`form-control flatpickr-color ${errors.length > 0 ? 'is-invalid' : ''}`"
                            v-model="policy.dateEnd"
                            :disabled="!policy.status || temporalDataStatus"
                            placeholder="Seleccione una fecha"
                            :state="errors.length > 0 ? false : null"
                          />
                            <!-- @input="verifyDatePolicy('dateEnd', policy, policy.dateEnd)" -->
                            <!-- :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" -->
                          <!-- <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback> -->
                        </b-form-group>
                      </ValidationProvider>
                    </td>
                    <td scope="row">
                      <ValidationProvider :name="'días política '+(index+1)" rules="required|max_value:366" >
                        <b-form-group class="custom-input-margin" slot-scope="{ errors }">
                          <b-form-input
                            step="1"
                            min="0"
                            max="366"
                            class="form-control"
                            type="number"
                            :disabled="temporalDataStatus || !policy.status"
                            v-model="policy.days"
                            :state="errors.length > 0 ? false : null"
                            @keypress="onlyNumber"
                          />
                          <!-- <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback> -->
                        </b-form-group>
                      </ValidationProvider>
                    </td>
                    <td class="text-center">
                      <b-form-checkbox
                        v-model="policy.status"
                        :disabled="temporalDataStatus"
                        v-b-tooltip.hover
                        title="Estado"
                        switch
                        inline
                        :unchecked-value="false"
                        class="custom-control-success m-0"
                      />
                    </td>
                    <td class="text-center">
                      <b-button
                        size="sm"
                        v-b-tooltip.hover
                        title="Borrar"
                        @click="deletePolicy(policy)"
                        variant="danger"
                        v-if="!temporalDataStatus"
                      > <feather-icon icon="Trash2Icon" size="12" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-overlay>
          </div>

          <div v-else>
            <b-alert show variant="danger" >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="InfoIcon"
                />
                <span class="ml-25">Esta membresía no tiene políticas de cancelación agregados.</span>
              </div>
            </b-alert>
          </div>

          <hr>
        </div>
      </b-col>

    </b-row>
  </b-tab>
</template>
<script>
import { mapActions, mapMutations} from 'vuex'
import { stringAleatorio, showAlertMessage, makeTime } from '@/helpers/helpers'
import { utils } from '@/modules/fivesClub/mixins/utils'
import * as moment from 'moment'
import flatPickr from "vue-flatpickr-component";

export default {
  mixins: [utils],
	components: { flatPickr },
  created(){
    this.setTableActionButton()
  },
  props: {
    currentPolicies: {
      type: Array,
      required: true,
    },
    newPolicies: {
      type: Array,
      required: true,
    },
    isUpdatingPolicies: {
      type: Boolean,
      required: true,
    },
    temporalDataStatus: {
      type: Boolean,
      required: true,
    },
    idMembership: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      newPolicy: {
        key: stringAleatorio(),
        name: null,
        dateInit: null,
        dateEnd: null,
        days: null,
        status: true,
        priority: null,
      },
      dateInConfig: {},
      currentPoliciesFields: [
        { key:'priority', label: 'Prioridad', class: 'font-weight-bold wth-2' },
        { key:'name', label: 'Nombre', class: 'font-weight-bold' },
        { key:'dateInit', label: 'Fecha inicio', sortable: true },
        { key:'dateEnd', label: 'Fecha fin', sortable: true },
        { key:'days', label: 'Días antes de penalizar' },
        // { key:'actions', label: 'Activo' },
        { key:'actions', label: 'Acciones', class: 'text-center' },
      ],
      isLoadingPriority: false
    }
  },
  computed:{
    setDateInConfigGlobal() {
      return {
        minDate: moment().format("YYYY-MM-DD"),
        // disable: this.disabledCurrentDates
      }
    },
    setDateOutConfigGlobal(){
      return {
        minDate: this.newPolicy.dateInit,
        // disable: this.disabledCurrentDates
      }
    },
    disabledCurrentDates(){
      const dates = this.policies.map(policy => {
        return {
          from: policy.dateInit,
          to: policy.dateEnd
        }
      })
      return dates
    },

    policies(){
      return this.currentPolicies.concat(this.newPolicies).sort((a, b) => a.priority > b.priority ? 1 : -1)
    }
  },
  methods: {
    ...mapMutations('fivesClubCatalogs', ['addPolicyMembership', 'removePolicyMembershipTemporal']),
    ...mapActions('fivesClubCatalogs', ['deleteInMembership']),
    async deletePolicy(policy){
      const { id, key } = policy // verifico si es local o un registro

      if (id) {
        const payloadAction = { title: '¿Está seguro de borrar esta política?', text: 'Esta acción no se puede deshacer', showDenyButton: true, confirmButtonText: 'Sí, borrar', denyButtonText: 'Cancelar'}
        const { isConfirmed } = await this.$swal.fire(payloadAction)
        if( isConfirmed ){
          this.$emit('set-loader', {loader: 'isUpdatingPolicies', value: true})
          const payload = { idMembership: this.idMembership, idPolicy: id }
          const { status, message } = await this.deleteInMembership(payload) // message viene igual en la respuesta
          if( status ) this.$emit('reload-data', message)
          this.$emit('set-loader', {loader: 'isUpdatingPolicies', value: false})
        }
      } else this.removePolicyMembershipTemporal(key)
    },
    async addPolicy(){
      const isValid = await this.$refs.membershipAddPoliciesForm.validate()
      if (isValid) {
        const validDate = this.policies.find(policy => (policy.dateInit == this.newPolicy.dateInit && policy.dateEnd == this.newPolicy.dateEnd))

        if (!validDate) {
          this.newPolicy.priority = this.policies.length + 1
          this.addPolicyMembership(this.newPolicy)
          this.newPolicy = {
            key: stringAleatorio(),
            name: null,
            dateInit: null,
            dateEnd: null,
            days: null,
            status: true,
            priority: null,
          }
          await this.$refs.membershipAddPoliciesForm.reset()
        } else showAlertMessage('No se ha actualizado la fecha', 'InfoIcon', `Ya existe una política con el rango de fechas seleccionado: ${validDate.name}`, 'warning', 4000, 'bottom-right' )
      }
    },
    setTableActionButton() {
      if (this.temporalDataStatus) {
        this.currentPoliciesFields = this.currentPoliciesFields.filter(fields => fields.key != 'delete')
      }
    },
    rowClass(item) {
      // if (!item || type !== "row") return
      // if (item.id === this.setDetailClass) return "table-success"
      if (!item.id) return "table-primary"
    },
    checkPriority(){
      if (this.policies.some(policy => policy.priority == 0)) return showAlertMessage('No se han actualizado las politicas de cancelación', 'InfoIcon', `Existe una politica sin prioridad: ${priorityTaken.name}`, 'warning', 4000, 'bottom-right' )
      const duplicated = []
      const beyondLimit = []
      this.policies.forEach(item => {
        const priorityTaken = this.policies.find(policy => (policy.priority == item.priority && (item.id ? item.id != policy.id : item.key != policy.key)))
        if (priorityTaken) {
          item.priority = null
          duplicated.push(item.name)
        }
        if (item.priority > this.policies.length) {
          item.priority = null
          beyondLimit.push(item.name)
        }
      })

      if (duplicated.length > 0) return showAlertMessage('No se ha actualizado la prioridad', 'InfoIcon', `ya existe una politica con esta prioridad: ${duplicated}`, 'warning', 4000, 'bottom-right' )
      if (beyondLimit.length > 0) return showAlertMessage('No se ha actualizado la prioridad', 'InfoIcon', `Limite de prioridad alcanzado en prioridad ${beyondLimit}`, 'warning', 4000, 'bottom-right' )
      return true
    }
  }
}
</script>
<style lang="scss" scoped>
	@import "@core/scss/vue/libs/vue-flatpicker.scss";

  .flatpickr-color{
    background-color: white !important;
  }
  .flatpickr-color:disabled{
    background-color: #efefef !important;
  }
</style>
<style>
  .custom-table-overflow{
    max-height: 400px;
    overflow-x: hidden;
  }
  .custom-table-padding-tr td{
    padding: 0.70rem 1rem;
    align-self: center;
    word-break: break-word;
  }
  .custom-table-title-padding{
    padding: 0.70rem 1rem !important;
    align-self: center;
    word-break: break-word;
  }
  .wth-2{
    width: 2rem !important;
  }

  .wth-4{
    width: 4rem !important;
  }
</style>