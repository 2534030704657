<template>
  <!-- Beneficios de Membresía -->
  <b-tab title="Beneficios">
    <b-row class="mt-1 border rounded p-1">
      <b-col md="6"> <!-- Beneficios -->
        <div>
          <h4>Beneficios actualmente en la membresía</h4>
          <b-input
            v-if="benefitsAvailable.length"
            :disabled="temporalDataStatus"
            v-model="filterCurrentBenefits"
            placeholder="Busqueda por nombre en la tabla"
            class="mb-1"
          />
          <b-table
            v-if="currentBenefits.length"
            :items="currentBenefits"
            :fields="currentBenefitsFields"
            :filter="filterCurrentBenefits"
            responsive
            sticky-header="500px"
          >
            <template #cell(can)="data">
              <ValidationProvider :name="'cantidad-beneficio-'+data.index+1" rules="required" >
                <b-form-group class="custom-input-margin" slot-scope="{ valid, errors }">
                  <b-form-input
                    step="0.01"
                    min="0.00"
                    class="form-control"
                    type="number"
                    :disabled="temporalDataStatus"
                    v-model="data.item.can"
                    :state="errors[0] ? false : valid ? true : null"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </template>

            <template #cell(discount)="data">
              <ValidationProvider :name="'descuento-beneficio-'+data.index+1" rules="required" >
                <b-form-group class="custom-input-margin" slot-scope="{ valid, errors }">
                  <b-form-input
                    min="0.00"
                    max="100.00"
                    class="form-control"
                    type="number"
                    :disabled="temporalDataStatus"
                    v-model="data.item.discount"
                    :state="errors[0] ? false : valid ? true : null"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </template>

            <template #cell(delete)="data">
              <b-button
                size="sm"
                @click="deleteBenefit(data.item)"
                v-if="!temporalDataStatus"
                variant="danger"
                v-can="'fivesclub_membership_show_membership_list_action_button_edit_payment'"
              ><feather-icon icon="Trash2Icon" size="12" /></b-button>
            </template>
          </b-table>

          <div v-else>
            <b-alert show variant="danger" >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="InfoIcon"
                />
                <span class="ml-25">Esta membresía no tiene beneficios agregados.</span>
              </div>
            </b-alert>
          </div>
        </div>
      </b-col>
      <b-col md="6"> <!-- Nuevos beneficios -->
        <div>
          <h4>Agregar nuevos beneficios a la membresía</h4>
          <b-input
            v-if="benefitsAvailable.length"
            :disabled="temporalDataStatus"
            v-model="filterAvailableBenefits"
            placeholder="Busqueda por nombre en la tabla"
            class="mb-1"
          />

          <b-table
            v-if="benefitsAvailable.length"
            :items="benefitsAvailable"
            :fields="newBenefitsFields"
            :filter="filterAvailableBenefits"
            responsive
            sticky-header="500px"
            sticky-top
          >

            <template #cell(qty)="data">
              <ValidationProvider :name="'cantidad-nuevo-beneficio-'+data.index+1" rules="" >
                <b-form-group class="custom-input-margin" slot-scope="{ valid, errors }">
                  <b-form-input
                    step="0.01"
                    min="0.00"
                    class="form-control"
                    type="number"
                    :disabled="temporalDataStatus"
                    v-model="data.item.qty"
                    @input="updateDataBenefit(data.item)"
                    @keypress="onlyNumber"
                    :state="errors[0] || !valid ? false : null"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </template>

            <template #cell(discount)="data">
              <ValidationProvider :name="'descuento-nuevo-beneficio-'+data.index+1" rules="" >
                <b-form-group class="custom-input-margin" slot-scope="{ valid, errors }">
                  <b-form-input
                    min="1.00"
                    max="100.00"
                    class="form-control"
                    type="number"
                    :disabled="temporalDataStatus"
                    v-model="data.item.discount"
                    @input="updateDataBenefit(data.item)"
                    @keypress="onlyNumber"
                    :state="errors[0] || !valid ? false : null"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </template>

            <template #cell(add)="data">
              <b-form-checkbox
                v-model="data.item.isChecked"
                :disabled="temporalDataStatus"
                switch
                inline
                :unchecked-value="false"
                class="custom-control-success"
                @change="newBenefit(data.item)"
              />
            </template>
          </b-table>

          <div v-else>
            <b-alert show variant="danger" >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="InfoIcon"
                />
                <span class="ml-25">Sin nuevos beneficios para agregar</span>
              </div>
            </b-alert>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapActions, mapMutations} from 'vuex'
import { utils } from '@/modules/fivesClub/mixins/utils'

export default {
  mixins: [utils],
  created(){
    this.setTableActionButton()
  },
  props: {
    currentBenefits: {
      type: Array,
      required: true,
    },
    benefitsAvailable: {
      type: Array,
      required: true,
    },
    isUpdatingBenefits: {
      type: Boolean,
      required: true,
    },
    temporalDataStatus: {
      type: Boolean,
      required: true,
    },
    idMembership: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      filterCurrentBenefits:'',
      filterAvailableBenefits: '',
      currentBenefitsFields: [
          { key:'nameBenefit', label: 'NOMBRE BENEFICIO', class: 'font-weight-bold' },
          { key:'can', label: 'CANTIDAD' },
          { key:'discount', label: 'DESCUENTO' },
          { key:'delete', label: 'Borrar', class: 'text-center' },
        ],
      newBenefitsFields: [
        { key:'name', label: 'NOMBRE BENEFICIO', class: 'font-weight-bold' },
        { key:'qty', label: 'CANTIDAD' },
        { key:'discount', label: 'DESCUENTO' },
        { key:'add', label: 'Agregar', class: 'text-center' },
      ],
    }
  },
  methods: {
    ...mapMutations('fivesClubCatalogs', ['addRemoveNewBenefitMembership', 'updateNewBenefitMembership']),
    ...mapActions('fivesClubCatalogs', ['deleteInMembership']),
    async deleteBenefit(benefit){
      const payloadAction= { title: '¿Está seguro?', text: '¿Seguro de borrar esta membresía?', showDenyButton: true, confirmButtonText: 'Sí, borrar', denyButtonText: 'Cancelar'}
      const {isConfirmed} = await this.$swal.fire(payloadAction)

      if(isConfirmed){
        this.$emit('set-loader', {loader: 'isUpdatingBenefits', value: true})
        const { idBenfit:idBenefit } = benefit // es idBenefit
        const payload = { idMembership: this.idMembership, idBenefit }
        const { status, message } = await this.deleteInMembership(payload) // message viene igual en la respuesta
        if( status ) this.$emit('reload-data', message)
        this.$emit('set-loader', {loader: 'isUpdatingBenefits', value: false})
      }
    },
    newBenefit(benfit){
      const { isChecked, qty, discount, id } = benfit
      const payload = { isChecked, can: qty ? parseFloat(qty) : 0, discount: discount ? parseFloat(discount): 0, idBenefit:id, initCan: 0, endCan : 0}
      this.addRemoveNewBenefitMembership(payload)
    },
    updateDataBenefit(benfit){
      const { isChecked, qty, discount, id } = benfit
      if(isChecked){
        const payload = { isChecked, can: qty ? parseFloat(qty) : 0, discount: discount ? parseFloat(discount): 0, idBenefit:id, initCan: 0, endCan : 0 }
        this.updateNewBenefitMembership(payload)
      }
    },
    setTableActionButton(){
      if (this.temporalDataStatus) {
        this.currentBenefitsFields = this.currentBenefitsFields.filter(fields => fields.key != 'delete')
        this.newBenefitsFields = this.newBenefitsFields.filter(fields => fields.key != 'add')
      }
    }
  }
};
</script>

<style lang="scss" scoped>

</style>